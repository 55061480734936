@import './theme.css';
@import './plugins.css';
@import './shared/layout.css';
@import './shared/layout_landing.css';
@import './shared/navbar.css';
@import './shared/footer.css';
@import './responsive.css';

.wrapper {
  height: inherit;
}

.section_wrap {
  min-height: 100%;
  justify-content: center;
}

.section_wrap .section_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  /* width: 400px; */
}

.section_layout .layout_col {
  align-self: flex-start;
  flex: 1;
}

.section_wrap .titles_wrap {
  justify-content: center;
  width: 100%;
  /* flex: 1;
  align-items: center; */
}

.signin_connect_wrap .titles_wrap {
  flex: 1;
  align-items: center;
}

.section_wrap .title_main_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section_wrap .title_main_wrap .consent-text a {
  color: #fff;
  text-decoration: underline;
  opacity: 0.85;
}

.section_wrap .title_main_wrap .consent-text a:hover {
  opacity: 1;
}

.section_wrap .titles_wrap .title_main {
  font-size: 2rem;
  padding-bottom: 6px;
}

.section_layout_auth_layout .social-connect-wrap {
  width: 400px;
  order: 0;
}

.social-connect-wrap {
  text-align: center;
  margin-top: 1rem;
}

.social-connect-wrap p {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.section_layout_auth_layout .titles_wrap {
  order: 1;
  width: 400px;
}

.section_wrap.section_wrap_recovery .titles_wrap .title_main {
  font-size: 2.7rem;
}

.input-form {
  padding: 10px 0;
  padding-top: 0;
  /* width: 350px; */
}

.input-form .input-field {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.input-form label {
  /* display: none; */
}

.input-form input {
  padding: 7px 14px;
  font-family: "Inter";
  font-weight: 500;
  border-radius: 3px;
  color: var(--color-app-primary);
  background-color: var(--bg-color-form-input);
  border: 1px solid #e2e2e200;
}

.input-form input::placeholder,
.input-form input:focus {
  font-weight: 500;
}

.input-form input:focus {
  /* color: var(--bg-color-faq-body); */
  color: #111;
  background-color: var(--color-app-primary);
}

#auth_form_error,
#form_error {
  padding: 7px;
  color: #ff5f5f;
  font-size: 18px;
}

#auth_form_error::first-letter,
#form_error::first-letter {
  text-transform: capitalize;
}

#auth_form_error::before,
#form_error::before {
  content: '⚠️';
}

.form_wrapper {
  display: none;
}

.form_wrapper.activated {
  display: block;
}

.form_wrapper.activated+.spinner-wrap {
  display: none;
}

#form_auth.loaded {
  display: none;
}

#form_auth.inactive {
  margin: 0;
}

#form_auth.inactive .input-field,
#form_auth.inactive .cta_wrap {
  display: none;
  margin: 0;
}

#form_auth .cta_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 6px;
}

#form_auth .cta_wrap .cta_btn {
  width: 100%;
  padding: 7px 0;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: 500;
}

#form_auth.inactive .always-active {
  display: flex;
}

.if-processing {
  display: none;
}

.processing .if-processing {
  display: unset;
}

.processing .if-not-processing {
  display: none;
}

.if-success {
  display: none;
}

.success .if-success {
  display: unset;
}

.connect-btns {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  flex-wrap: wrap;
  background: transparent;
  margin: 0 auto;
  margin-top: 20px;
  z-index: 1;
  width: 100%;
  flex: 1;
}

.connect-btns.connect-btns-colview {
  flex-direction: column;
}

.connect-btns .subtitle {
  text-align: center;
  margin-bottom: 15px;
  opacity: 0.75;
  width: 100%;
}

.connect-btns button {
  height: 46px;
  display: flex;
  /* justify-content: flex-start; */
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 0;
  margin-bottom: 8px !important;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #fff;
  float: 1;
  position: relative;
  flex: 1;
  width: 100%;
  border: none;
  cursor: pointer;
}

.connect-btns.connect-btns-colview button {
  border-radius: 4px !important;
}

.connect-btns button img {
  width: 22px;
}

.connect-btns button span {
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
  display: none;
}

.connect-btns.connect-btns-colview button span {
  display: unset;
}

.connect-btns .connect-btn-twitch {
  background: #9146ff;
  background-color: #9146ff;
  color: #fff;
}

.connect-btns .connect-btn-twitch:hover {
  background-color: #6910ee;
}

.connect-btns .connect-btn-youtube {
  /* background-color: #e62117; */
  background-color: #fff;
  color: #fff;
  border-radius: 0 4px 4px 0;
}

.connect-btns .connect-btn-youtube:hover {
  background-color: #282828 !important;
}

.connect-btns .connect-btn-youtube img {
  width: 32px;
}

.connect-btns .connect-btn-youtube img.on_hover {
  display: none;
}

.connect-btns .connect-btn-youtube:hover img.on_hover {
  display: unset;
}

.connect-btns .connect-btn-youtube:hover img:not(.on_hover) {
  display: none;
}

.connect-btns .connect-btn-youtube:hover {
  background-color: rgb(219, 18, 8);
}

.connect-btns .connect-btn-facebook {
  background-color: #1877f2;
  color: #fff;
  border-radius: 4px 0 0 4px;
}

.connect-btns .connect-btn-facebook:hover {
  background-color: #0e64d4;
}

.connect-btn-dlive {
  background-color: #ffd300;
  color: #000 !important;
}

.connect-btn-dlive:hover {
  background-color: #d4b209;
}

.spinner-wrap {
  position: relative;
  height: 175px;
  align-self: center;
}

.spinner-wrap .yt {
  background-color: #e62117;
}

.spinner-wrap .twitch {
  background-color: #9146ff;
}

.spinner-wrap .fb {
  background-color: #1877f2;
}

.spinner-wrap .dlive {
  background-color: #ffd300;
}

.spinner-wrap .magic-link {
  background-color: #3ba21f;
}

.oauth-appName {
  text-transform: capitalize;
}

.oauth-appAvatar.twitch {
  background-image: url(/assets/images/icons/login-with-twitch.svg);
}

.oauth-appAvatar.facebook {
  background-image: url(/assets/images/icons/login-with-facebook.svg);
}

.oauth-appAvatar.youtube {
  background-image: url(/assets/images/icons/login-with-youtube.svg);
}

.if-error {
  display: none;
}

.errored .if-error {
  display: unset;
}

.errored .if-not-error {
  display: none !important;
}

.ack-message {
  text-align: center !important;
}

.consent-text {
  font-size: 14px;
  opacity: 0.85;
  /* margin-top: 20px; */
  line-height: 24px;
  font-family: "inter";
}

.promos_consent_wrap {
  display: flex;
  align-items: flex-start;
  margin-top: 15px;
}

.promos_consent_wrap input {
  margin-top: 6px;
  margin-right: 10px;
}

.section_wrap .titles_wrap .desc {
  text-align: left;
  width: unset;
  font-size: 1rem;
  opacity: 1;
  padding-bottom: 15px;
}

#when-signup,
#when-signup.active+#when-signin {
  visibility: hidden;
  width: 0;
  height: 0;
  flex: 0;
}

#when-signup.active {
  visibility: visible;
  /* width: unset; */
  flex: unset;
  width: 400px;
  height: auto;
  justify-content: flex-start;
  text-align: left;
  align-items: flex-start;
  display: block;
}

.connect_user_wrap {
  margin-top: 20px;
  position: relative;
}

.connect_user_wrap .anchor {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 12px;
  /* text-decoration: underline; */
}

.connect_user_card {
  width: 100%;
  display: flex;
  padding: 20px 20px 30px 20px;
  background-color: #1e1a27;
  border-radius: 4px;
}

.connect_user_card img {
  width: 64px;
  border-radius: 4px;
}

.connect_user_card .user_desc {
  padding: 7px 20px;
}

.connect_user_card .user_desc h2 {
  font-size: 1.4rem;
}

.connect_user_card .user_desc p {
  /* font-size: 1.4rem; */
}

.hint_wrap {
  padding: 10px 20px;
  border-radius: 6px;
  background-color: #4c088029;
  display: inline-block;
  border: 2px dashed #4c088061;
}

/* For Portrail - Phone view */
@media (max-width: 812px) and (orientation: portrait) {
  .section_wrap .section_layout {
    flex-direction: column;
  }

  #form_auth .cta_wrap {
    justify-content: center;
    flex-direction: column;
  }
}

/* For portrait - phone view (lower) */
@media (max-width: 600px) and (orientation: portrait) {
  .titles_wrap .title_main {
    margin: unset;
    text-align: left;
  }

  .section_wrap .titles_wrap .desc {
    margin: unset;
    text-align: left !important;
    max-width: 100% !important;
  }

  .cta_wrap .cta_btn {
    border-radius: 4px !important;
  }

  .input-form {
    padding-top: 0.5rem;
  }

  .consent-text {
    text-align: left;
  }
}